@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Semibold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "General Sans";
  src: url("../../../public/fonts/GeneralSans-Extralight.otf")
    format("opentype");
  font-weight: 200;
}

body {
  font-family: "General Sans" !important;
}
