@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueHeavy.otf") format("opentype");
  font-weight: 700;
}

/* @font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueBold.otf") format("opentype");
  font-weight: 600;
} */

@font-face {
  font-family: "Helvetica Neue";
  /* src: url("../../../public/fonts/HelveticaNeueBlack.otf") format("opentype"); */
  src: url("../../../public/fonts/HelveticaNeueRoman.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueMedium.otf") format("opentype");
  /* src: url("../../../public/fonts/HelveticaNeueThin.otf") format("opentype"); */
  font-weight: 500;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueRoman.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueThin.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../public/fonts/HelveticaNeueLight.otf") format("opentype");
  font-weight: 200;
}

body {
  /* font-family: "General Sans" !important; */
  font-family: "Helvetica Neue" !important;
}
